<template>
  <div>
    <ReportPage>
      <table v-if="data">
        <thead>
          <tr>
            <td>
              <!--place holder for the fixed-position header-->
              <div class="print-header">
                <ReportHeader />
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <!--*** CONTENT GOES HERE ***-->
              <div
                v-if="data.details"
                class="px-3 pt-6"
              >
                <v-card-title class="pb-0 text-uppercase">
                  {{ $t('report.screening.info.title') }}
                </v-card-title>
                <v-card-text>
                  <div style="border: 1px solid rgb(179 179 179);padding: 10px;">
                    <table class="simple">
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.info.screeningID') }}
                        </td>
                        <td>#{{ data.details.screeningId }}</td>
                      </tr>
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.info.screeningAt') }}
                        </td>
                        <td>{{ moment(data.details.screeningDate).format('YYYY-MM-DD HH:mm:ss') }}</td>
                      </tr>
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.info.screeningBy') }}
                        </td>
                        <td>{{ data.details.screeningBy }}</td>
                      </tr>
                    </table>
                  </div>
                  <span class="grey--text pr-2 text-caption float-right">{{ $t('report.screening.info.engine') }}: {{ data.details.screeningInfo.engine }} ({{ data.details.screeningInfo.engineVersion }} / {{ data.details.screeningInfo.endpointVersion }})</span>
                </v-card-text>
                <v-card-title class="pb-0 text-uppercase">
                  {{ $t('report.screening.criteria.title') }}
                </v-card-title>
                <v-card-text>
                  <div style="border: 1px solid rgb(179 179 179);padding: 10px;">
                    <table class="simple">
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.criteria.name') }}
                        </td>
                        <td>{{ data.details.screeningInfo.name }}</td>
                      </tr>
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.criteria.entityType') }}
                        </td>
                        <td>{{ data.details.screeningInfo.entityType }}</td>
                      </tr>
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.criteria.DOB') }}
                        </td>
                        <td>{{ data.details.screeningInfo.entityType == 'Individual' ? (data.details.screeningInfo.yob || '-') : 'N/A' }}</td>
                      </tr>
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.criteria.gender.label') }}
                        </td>
                        <td>{{ data.details.screeningInfo.entityType == 'Individual' ? (data.details.screeningInfo.gender || $t('report.screening.criteria.gender.all')) : 'N/A' }}</td>
                      </tr>
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.criteria.country.label') }}
                        </td>
                        <td>
                          <template v-if="(data.details.screeningInfo.countries || []).length > 0">
                            {{ data.details.screeningInfo.countries.slice(0, 3).join(', ') }}
                            <span v-if="data.details.screeningInfo.countries.length > 3">
                              ({{ $t('report.screening.criteria.country.more', { count: data.details.screeningInfo.countries.length - 3 }) }})
                            </span>
                          </template>
                          <template v-else>
                            {{ $t('report.screening.criteria.country.all') }}
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.criteria.category.label') }}
                        </td>
                        <td>
                          <template v-if="(data.details.screeningInfo.categories || []).length > 0">
                            <ScreeningCategoryChips :category="data.details.screeningInfo.categories.slice(0,3)" />
                            <span v-if="data.details.screeningInfo.categories.length > 3">
                              ({{ $t('report.screening.criteria.category.more', { count: data.details.screeningInfo.categories.length - 3 }) }})</span>
                          </template>
                          <template v-else>
                            {{ $t('report.screening.criteria.category.all') }}
                          </template>
                        </td>
                      </tr>
                      <!-- <tr>
                        <td class="text-h6">
                          Screening List
                        </td>
                        <td>{{ data.details.screeningInfo.screeningLists || 'All Screening List' }}</td>
                      </tr> -->
                    </table>
                  </div>
                </v-card-text>
                <v-card-title class="pb-0 text-uppercase">
                  {{ $t('report.screening.results.title') }}
                </v-card-title>
                <v-card-text>
                  <div style="border: 1px solid rgb(179 179 179);padding: 10px;">
                    <table class="simple">
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.results.count') }}
                        </td>
                        <td>{{ data.details.hitDetails.length }}</td>
                      </tr>
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.results.riskScore') }}
                        </td>
                        <td>{{ data.details.risk.riskScore ? `${data.details.risk.riskScore}/10` : 'N/A' }}</td>
                      </tr>
                      <tr>
                        <td class="text-h6">
                          {{ $t('report.screening.results.riskLevel') }}
                        </td>
                        <td>{{ data.details.risk.riskLevel || 'N/A' }}</td>
                      </tr>
                    </table>
                  </div>
                </v-card-text>
              </div>
              <!-- {{ loading ? 'Loading...' : '' }} -->
              <!-- Footer -->
              <div class="footer">
                <ReportFooter
                  :organization="organization"
                  :export-by="exportBy"
                  :export-at="exportAt"
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <!--place holder for the fixed-position footer-->
              <div
                class="page-footer-space"
                style="height: 110px"
              >
                <!-- I'm The Footer -->
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </ReportPage>
    <template v-if="data && data.entities">
      <ReportPage
        v-for="(entity, key) in data.entities"
        :key="key"
      >
        <ReportContent
          :data="entity"
          :organization="organization"
          :export-by="exportBy"
          :export-at="exportAt"
          :similarity-level="data.details.hitDetails.find(item => item.entityID == entity.entityID).similarityLevel || 'N/A'"
        />
      </ReportPage>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ReportPage from '@/components/reports/common/ReportPage'
import ReportHeader from '@/components/reports/Screening/ReportHeader'
import ReportFooter from '@/components/reports/common/ReportFooter'
import ReportContent from '@/components/reports/ScreeningEntity/ReportContent'
import ScreeningCategoryChips from '@/components/common/ScreeningCategoryChips'

export default {
  components: {
    ReportPage,
    ReportHeader,
    ReportFooter,
    ReportContent,
    ScreeningCategoryChips
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    organization: {
      type: String,
      default: ''
    },
    exportBy: {
      type: String,
      default: ''
    },
    exportAt: {
      type: String,
      default: ''
    }
  },
  watch: {
    data: {
      handler (val) {
        window.document.title = `RBA_Screening_Report_#${val.details.screeningId}`
      },
      deep: true
    }
  },
  data () {
    return {
      // loading: false
    }
  },
  methods: {
    ...mapActions('scan', [
      'getScreeningResultsEntity'
    ])
  }
}
</script>

<style scoped>

table.simple {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* border: 1px solid #ddd; */
  font-size: 1.25rem !important;
}

table.simple td {
  padding-left: 10px;
}

table.simple tr td:first-child {
  width: 185px;
}

/* .page01 {
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f78484;
} */
</style>
