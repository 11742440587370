<template>
  <div class="report-header">
    <img
      src="@/assets/reports/logo.webp"
      class="report-app-logo"
    >
    <img
      src="@/assets/reports/Subtraction_46.svg"
      class="report-bg"
    >
    <v-card-title
      class="py-0"
    >
      <span
        class="font-weight-bold text-h4 text-uppercase"
        style="padding-top: 4px;padding-left: 12px;position:relative;"
      >
        {{ $t('report.screening.header.title') }}
      </span>
    </v-card-title>
  </div>
</template>

<script>
export default {
  props: {
  }
}
</script>

<style scoped>
.report-header {
  padding: 68px 0px 0px;
}

.report-bg {
  position: absolute;right: 0;top: 0;height: 110px;user-select: none;
}

.report-app-logo {
  top: 22px;height: 58px;left: 24px;position: absolute;user-select: none;
}
</style>
